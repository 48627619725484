import React from 'react';
import { UiText, UiView, useForm } from '@dex/bubl-ui';
import moment from 'moment';

import TimeSlots from '../../elements/TimeSlots/TimeSlots';
import Button from '../../elements/Button/Button';
import FieldDate from '../../elements/Fields/FieldDate/FieldDate';

import styles from './BlockBookingModel.module.scss';

import FieldSelect from '../../elements/Fields/FieldSelect/FieldSelect';
import Space from '../../elements/Space/Space';

const BlockBookingModelSelectDate: React.FC<BlockBookingModelSelectDateProps> = (props) => {

    const { info, slots, date, setDate, timeSlots, setTimeSlots, setCheck, setNumber } = props;

    const form = useForm({
        date: date.toDate(),
        number: info.minSize
    });

    const numberOptions: any = [];

    for (let i = info.minSize; i <= info.maxSize; i++) {
        numberOptions.push({
            value: i,
            label: i + " Sessions"
        });
    }

    return (

        <>

            <UiView className={styles.body}>

                <FieldDate
                    form={form}
                    name={"date"}
                    label="Date of First Session"
                    defaultValue={form.defaults.date}
                    onChange={(value: any) => setDate(moment(value))}
                    isClearable={false}
                    required={true}
                    minDate={new Date(moment(info.periodStartDate).format("YYYY-MM-DD"))}
                    maxDate={new Date(moment(info.periodEndDate).format("YYYY-MM-DD"))}
                />

                <Space height={2} />

                <FieldSelect
                    form={form}
                    name={"number"}
                    label="Number of Sessions"
                    defaultValue={form.defaults.number}
                    required={true}
                    options={numberOptions}
                    isClearable={false}
                    onChange={(option: any) => { setNumber(parseInt(option.value)) }}
                />

                <Space height={2} />

                <UiText className={styles.timeSloTitle}>Select Timeslot</UiText>

                <TimeSlots
                    className={styles.timeSlots}
                    courtId={info.id}
                    slots={slots || []}
                    message={info.message}
                    title={null}
                    selected={timeSlots}
                    date={date}
                    setSelected={setTimeSlots}
                    isBlockBooking={true}
                />

                <Button
                    label={"Check Availability"}
                    theme={(timeSlots && timeSlots.length > 0) ? "primary" : "faded"}
                    disabled={!timeSlots || !timeSlots.length}
                    onClick={setCheck.bind(null, (timeSlots && timeSlots.length > 0) ? true : false)}
                    block
                />

            </UiView>

        </>
    )
}

interface BlockBookingModelSelectDateProps {
    [key: string]: any
}

export default BlockBookingModelSelectDate;